import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../../constants/route'
import { useDispatch, useSelector } from 'react-redux'
import { getEventDetailsAction } from '../../../actions/event'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import useCounter from '../../../hooks/useCounter'
import { format } from 'date-fns'

const EventListing = () => {
	const dispatch = useDispatch()
	const events = useSelector((state) => state.eventReducer)?.events
	useEffect(() => {
		if (!events?.length) {
			dispatch(getEventDetailsAction())
		}
	}, [])
	return (
		<div
			className="event-listing-container"
			style={{
				backgroundImage: `url(https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893062/braja/67114057-8f45-4d68-b079-8a1136c3be9d_aduxmn.jpg), url(https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893063/braja/1000_F_370716921_8sdvA1TRJ9D1eSn8M3nlOjlenjIigbJe_zllezb.jpg), url(https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893062/braja/IMG_20220417_135831_237_icfzaa.jpg)`,
				height: '100%',
				backgroundPosition: 'bottom right, top left, top right',
				backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
			}}
		>
			<Typography
				variant="h4"
				style={{
					marginBottom: '1rem',
				}}
			>
				Upcoming events
			</Typography>
			<div className="event-listing">
				{events
					.filter((e) => e.status)
					.map((e) => {
						const eventUiConfig = EVENTS_UI_CONFIG.find(
							(config) => config.uuid === e.eventId
						)
						const cardColor = `${Math.round(Math.random() * 255)}, 
						${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}`
						if (
							new Date(eventUiConfig.deadline).getTime() <
							Date.now()
						)
							return null
						return (
							<Link
								to={
									eventUiConfig.shortLink ||
									`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventUiConfig.uuid}`
								}
							>
								<div
									className="event-card"
									style={{
										backgroundImage: `linear-gradient(
										45deg,
										rgba(${cardColor}, 0.20),
										rgba(${cardColor}, 0.75)
									),
									url('https://wallpaper.dog/large/20562508.jpg')`,
									}}
								>
									<div className="event-card-text">
										<Typography variant="h6">
											{e.title}
										</Typography>
										<Typography variant="h3">
											Last Date:{' '}
											{format(
												new Date(
													eventUiConfig.deadline
												),
												'MMMM do yyyy'
											)}
										</Typography>
										<Typography variant="body">
											{eventUiConfig.dates}
										</Typography>
									</div>

									<Box
										sx={{
											dislay: 'flex',
											justifyContent: 'center',
											padding: '1rem',
										}}
									>
										<Countdown
											deadline={eventUiConfig.deadline}
										/>
									</Box>
									<Box
										sx={{
											marginBottom: '10px',
										}}
									>
										<Link
											to={
												eventUiConfig.shortLink ||
												`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventUiConfig.uuid}`
											}
										>
											<Button
												variant="contained"
												size="large"
												color="secondary"
											>
												Book Now
											</Button>
										</Link>
									</Box>
								</div>
							</Link>
						)
					})}
			</div>
		</div>
	)
}

export const Countdown = ({ deadline }) => {
	const counter = useCounter(deadline)

	return (
		<div className="event-list-counter">
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.days}</div>
				<div className="event-list-counter-text">Day</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.hours}</div>
				<div className="event-list-counter-text">Hour</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.min}</div>
				<div className="event-list-counter-text">Min</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.sec}</div>
				<div className="event-list-counter-text">Sec</div>
			</div>
		</div>
	)
}

export default EventListing
