import { createTheme, adaptV4Theme } from '@mui/material/styles'

export const COLOR = {
	// BASIC COLORS
	WHITE: '#FFFFFF',
	LIGHT_BLACK: '#3A170C',
	BLACK: '#000000',

	// PALLETE COLORS
	PRIMARY: '#4267B2',
	SECONDARY: '#FFFFFF',
}

export const theme = createTheme(
	adaptV4Theme({
		overrides: {
			MuiCssBaseline: {
				'@global': {
					'@font-face': [
						'Manrope-Regular',
						'Manrope-Medium',
						'Manrope-SemiBold',
						'Manrope-Bold',
					],
				},
			},
		},
		palette: {
			mode: 'light',
			primary: {
				main: COLOR.PRIMARY,
				light: '#00a5a5',
			},
			secondary: {
				main: '#ffc107',
			},
			error: {
				main: '#d32f2f',
			},
		},
		typography: {
			fontFamily: 'Manrope-Regular, Arial',
			fontWeightMedium: 600,
			h1: {
				fontSize: '3rem',
				fontWeight: 400,
				lineHeight: 1.44,
			},
			h2: {
				fontSize: '2.5rem',
			},
			h3: {
				fontSize: '2rem',
				fontWeight: 500,
			},
			h4: {
				fontSize: '1.5rem',
				fontWeight: 500,
				lineHeight: 1.15,
			},
			h5: {
				fontSize: '1.2rem',
				lineHeight: 1.2,
				fontWeight: 600,
			},
			h6: {
				fontWeight: 700,
				fontSize: '1rem',
			},
			subtitle1: {
				lineHeight: 1.6,
			},
			subtitle2: {
				lineHeight: 1.6,
			},
			body1: {
				fontSize: '14px',
				lineHeight: 1.3,
			},
			body2: {
				fontSize: '12px',
				lineHeight: 1.3,
			},
		},
	})
)
